import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FormControlLabel, RadioGroup } from '@mui/material'
import { BaseButton } from '../../../../core/components/Button'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { useHasCurrentUserCustomerServiceRole } from '../../../auth/hooks'
import Modal from './Modal'
import { useAssessmentContext } from '../state/useAssessment'
import { TStatus } from '../../../applicationHeader/type'
import { CustomRadio } from '../../../../core/components/CustomRadio'
import { fontFamily } from '../../../../core/styles/mixins'
import { useCancelReasons } from '../queries'

const defaultTextMixin = css`
  ${fontFamily('Inter')};
  color: ${({ theme }) => theme.colors.darkBlack};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`

const ModalOpenButtonStyled = styled(BaseButton)`
  && {
    min-width: 130px;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    margin-top: 8px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }
  }
`

const ActionButtonStyled = styled(BaseButton)`
  && {
    background: ${({ theme }) => theme.colors.blue500};
    color: ${({ theme }) => theme.colors.white};
    margin-left: -8px;
    padding: 8px 16px;

    &:hover {
      background: ${({ theme }) => theme.colors.blue700};
    }

    &:disabled {
      opacity: .5;
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ContentTitleStyled = styled.p`
  ${defaultTextMixin}
`

const RadioWrapStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;

  && {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .MuiFormControlLabel-root {
      margin-left: 0;
    }

    .MuiTypography-root {
      ${fontFamily('Inter')};
      font-size: 16px;
      margin-left: 4px;
      font-weight: 400;
      line-height: 24px;
      color: ${({ theme }) => theme.colors.darkBlack}
    }
  }
`

const FormTitleStyled = styled.p`
  ${defaultTextMixin};
  margin-top: 24px;

`

const AssessmentCancel = () => {
  const {
    headerQuery: headerData,
    headerUpdateMutation: { mutate, isLoading: isUpdating }
  } = useApplicationHeaderContext()
  const [cancelReasonCode, setCancelReasonCode] = useState('')
  const {
    setIsModalOpen,
    isModalOpen
  } = useAssessmentContext()
  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const hasAppropriateStatusToCancel = (['NEW', 'IN_PROGRESS', 'AUTO_APPROVED', 'ACCOUNT_REQUESTED', 'ACCOUNT_UPDATED', 'APPROVED_PENDING_RETRIEVER'] as TStatus[])
    .includes(headerData?.data?.status as TStatus)

  const { data: cancelReasonsData } = useCancelReasons(
    !!(isCustomerService && hasAppropriateStatusToCancel)
  )

  const handleChangeRadio = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => setCancelReasonCode(value),
    []
  )

  useEffect(() => () => setCancelReasonCode(''), [])

  if (!headerData
    || !isCustomerService
    || !hasAppropriateStatusToCancel
  ) {
    return null
  }
  return (
    <>
      <ModalOpenButtonStyled
        onClick={() => {
          setCancelReasonCode('')
          setIsModalOpen({ cancel: true })
        }}
        variant="contained"
      >
        Cancel
      </ModalOpenButtonStyled>
      <Modal
        title="Cancel application"
        content={(
          <ContentWrapper>
            <ContentTitleStyled>
              Cancelling this application will withdraw
              this application from the queue.
            </ContentTitleStyled>
            <RadioWrapStyled>
              <FormTitleStyled>Please select the cancellation reason:</FormTitleStyled>
              <RadioGroup
                onChange={handleChangeRadio}>
                {
                  cancelReasonsData?.map(({ code, value }) => (
                    <FormControlLabel
                      value={code}
                      label={value}
                      control={<CustomRadio />} />
                  ))
                }
              </RadioGroup>
            </RadioWrapStyled>
          </ContentWrapper>
        )}
        closeButtonText="Close"
        renderActionButton={() => (
          <ActionButtonStyled
            disabled={!cancelReasonCode || isUpdating}
            onClick={() => {
              if (!isUpdating) {
                mutate({ status: 'CANCELLED', cancelReasonCode })
                setIsModalOpen({ cancel: false })
              }
            }}
            autoFocus>
            Cancel application
          </ActionButtonStyled>
        )}
        isOpen={isModalOpen.cancel}
        handleClose={() => setIsModalOpen({ cancel: false })}
      />
    </>
  )
}

export default AssessmentCancel
