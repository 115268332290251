import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { format } from 'date-fns'
import { HistoryLogIcon } from '../../../../core/components/icons/HistoryLogIcon'
import { fontFamily } from '../../../../core/styles/mixins'
import Modal from './Modal'
import { useHistoryLog } from '../queries'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../../../../core/const/date'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import { CircleErrorIcon } from '../../../../core/components/icons/CircleErrorIcon'
import { RequestedIcon } from '../../../../core/components/icons/RequestedIcon'
import { TStatusAssessment } from '../type'
import { CreatedIcon } from '../../../../core/components/icons/CreatedIcon'
import { StartIcon } from '../../../../core/components/icons/StartIcon'
import { RevertIcon } from '../../../../core/components/icons/RevertIcon'
import { useIsSocialCreditOrganization } from '../../../auth/hooks'

const historyMap: any = {
  CREATED: <CreatedIcon width={20} height={20} />,
  SUBMITTED: <CreatedIcon width={20} height={20} />,
  ASSESSMENT: <StartIcon width={20} height={20} />,
  APPROVED: <CheckIcon width={20} height={20} />,
  AUTO_APPROVED: <CheckIcon width={20} height={20} />,
  DECLINED: <CircleErrorIcon width={20} height={20} />,
  CANCELLED: <CircleErrorIcon width={20} height={20} />,
  REVERTED: <RevertIcon width={20} height={20} />,
  ACCOUNT_REQUESTED: <RequestedIcon width={20} height={20} />,
  ACCOUNT_LINKED: <RequestedIcon width={20} height={20} />,
  RECONNECT_LINK_GENERATED: <RequestedIcon width={20} height={20} />,
  RESCORED: <RequestedIcon width={20} height={20} />,
  CANCELLED_OPTED_OUT: <CircleErrorIcon width={20} height={20} />,
  CANCELLED_NO_RESPONSE: <CircleErrorIcon width={20} height={20} />,
  CANCELLED_NEEDS_REAPPLY: <CircleErrorIcon width={20} height={20} />,
  CA_SEARCH_SUCCESS: <CheckIcon width={20} height={20} />,
  CA_SEARCH_FAILED: <CircleErrorIcon width={20} height={20} />,
  APPROVED_PENDING_RETRIEVER: <CheckIcon width={20} height={20} />,
  ACCOUNTS_WERE_EXCLUDED: <RequestedIcon width={20} height={20} />,
  ACCOUNTS_WERE_INCLUDED: <RequestedIcon width={20} height={20} />
}

const HistoryLogContainer = styled.div`
    ${fontFamily('Inter')};
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: ${({ theme }) => theme.colors.blue500};
    cursor: pointer;

    & > div {
        margin-left: 5px;
    }
`

const Log = styled.div`
    display: flex;
    margin-left: 2px;

    &:last-child .line {
        display: none;
    }
`

const IconWrapper = styled.div`
    margin-right: 10px;
    padding-top: 4px;
    position: relative;
`

const Line = styled.div`
    width: 1px;
    height: 48px;
    background-color: ${({ theme }) => theme.colors.grey300};
    margin: 0 auto;
`
const HistoryInfo = styled.div`
    ${fontFamily('Inter')};
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
`

const Status = styled.div`
    font-weight: 600;
`

const AdditionalInfo = styled.div`
    font-weight: 400;
`

const HistoryLog = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { refetch, data: historyLogData } = useHistoryLog()

  const hideUnderwriterName = useIsSocialCreditOrganization()

  return (
    <>
      <HistoryLogContainer onClick={() => refetch()
        .then(() => setIsModalOpen(true))}>
        <HistoryLogIcon />
        <div>View history log</div>
      </HistoryLogContainer>
      <Modal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        content={
          historyLogData
          && historyLogData.map(
            ({ createdAt, loanApplicationId, message, type, underwriterFirstName }) => (
              <Log>
                <IconWrapper>
                  {historyMap[type as TStatusAssessment]}
                  <Line className="line" />
                </IconWrapper>
                <HistoryInfo>
                  <Status>
                    {message}
                  </Status>
                  <AdditionalInfo>
                    {
                      `${format(new Date(createdAt), SLASH_DATE_FORMAT)} at ${format(
                        new Date(createdAt),
                        HOUR_MINUTE
                      )} ${[
                        'CREATED',
                        'ACCOUNT_LINKED',
                        'RESCORED',
                        'CA_SEARCH_FAILED',
                        'CA_SEARCH_SUCCESS',
                        'ACCOUNTS_WERE_INCLUDED',
                        'ACCOUNTS_WERE_EXCLUDED'
                      ].includes(type) || hideUnderwriterName || !underwriterFirstName ? '' : `by ${underwriterFirstName}`}`
                    }
                  </AdditionalInfo>
                </HistoryInfo>
              </Log>
            )
          )
        }
        title="History log"
        isHistoryLog
      />
    </>
  )
}

export default HistoryLog
