import styled from 'styled-components/macro'
import { format } from 'date-fns'
import React, { useMemo } from 'react'
import { flex, fontFamily } from '../../../../core/styles/mixins'
import { SpinnerIcon } from '../../../../core/components/icons/SpinnerIcon'
import { TStatusAssessment } from '../type'
import { CheckIcon } from '../../../../core/components/icons/CheckIcon'
import { CircleErrorIcon } from '../../../../core/components/icons/CircleErrorIcon'
import { TitleStyled } from './ApplicationDetail'
import { AssessmentProvider } from '../state/useAssessment'
import AssessmentApprove from './AssessmentApprove'
import AssessmentDecline from './AssessmentDecline'
import AssessmentStart from './AssessmentStart'
import { useAuthContext } from '../../../auth/state/useAuthState'
import { CircleTakenIcon } from '../../../../core/components/icons/CircleTakenIcon'
import { useApplicationHeaderContext } from '../../../applicationHeader/state/useApplicationHeader'
import { HOUR_MINUTE, SLASH_DATE_FORMAT } from '../../../../core/const/date'
import CustomTooltip from '../../../../core/components/CustomTooltip'
import { RequestedIcon } from '../../../../core/components/icons/RequestedIcon'
import HistoryLog from './HistoryLog'
import AssessmentDropOut from './AssessmentDropOut'
import AssessmentRevertToNew from './AssessmentRevertToNew'
import {
  useHasCurrentUserCustomerServiceRole,
  useIsPlendOrganization,
  useIsSocialCreditOrganization
} from '../../../auth/hooks'
import { TStatus } from '../../../applicationHeader/type'
import DeclinedDetailsLink from './DeclinedDetailsLink'
import AssessmentCancel from './AssessmentCancel'
import AssessmentSendReConnectLink from './sendReConnectLink/AssessmentSendReConnectLink'
import { useGenerateSccLinkOrganizationCheck } from '../hooks/useGenerateSccLinkOrganizationCheck'
import Rescore from './rescore/Rescore'
import { identityVerifiedText } from '../utils/identityUtils'
import DataOneIdentity from './DataOneIdentity'
import IdentityIcon from './IdentityIcon'
import MarkApplication from '../../../applicationHeader/components/MarkApplication'
import { FileCopyIcon } from '../../../../core/components/icons/FileCopyIcon'
import { useHandleCopy } from '../../../../core/hooks/useHandleCopy'
import { useAddressQuery } from '../../../applicationHeader/queries'
import CopyButton from '../../../../core/components/CopyButton'

const OverViewContainerStyled = styled.div`
  min-width: 380px;
  margin-bottom: 20px;
`

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.blue500};
`

const OverViewBoxStyled = styled.div`
  ${fontFamily('Inter')};
  margin-left: 8px;

  & h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.darkBlack};
  }

  & p {
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.grey600};
  }
`

const OverViewBodyStyled = styled.div`
  ${flex({ align: 'start' })};
  margin-top: 16px;
`

const LinksWrapStyled = styled.div`
  ${flex({ align: 'start' })};
  gap: 24px;
`

const OverViewHeaderStyled = styled.div<{ centered?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  margin-top: 8px;

  & > :first-child {
    margin-top: 4px;
  }
`

const PlendIdentity = styled.div`
  display: flex;
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const PlendIdentityDetailes = styled.div`
  cursor: pointer;
`

const BorrowerInfo = styled.div`
  display: flex;
  align-items: center;
`

const IdentityIconStyled = styled.div`
  cursor: pointer;
  margin-right: 4px;
`

const BorrowerName = styled.div`
  margin-right: 10px;
`
const IdentityWrapStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-top: 4px;
`

const CopyWrapperStyled = styled.div`
  ${fontFamily('Inter')};
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${({ theme }) => theme.colors.blue500};
  cursor: pointer;

  & > div {
    margin-left: 5px;
  }
`

const buttonsMap: any = {
  // eslint-disable-next-line
  APPROVED: (
    <>
      <AssessmentRevertToNew />
      <AssessmentDecline isSecondaryButton />
    </>
  ),
  AUTO_APPROVED: (
    <>
      <AssessmentRevertToNew />
    </>
  ),
  // eslint-disable-next-line
  DECLINED: (
    <>
      <AssessmentRevertToNew />
      <AssessmentApprove isSecondaryButton />
    </>
  )
}

type TPeriodProps = {
  statusAt: Date
  underwriterName?: string
}

function useStatusTextMap() {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()

  return useMemo(
    () => ({
      DECLINED: {
        status: 'Declined',
        icon: <CircleErrorIcon width={24} height={24} />,
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          `${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )}${isSocialCreditOrganization ? '' : ` by ${underwriterName}`}`
      },
      CANCELLED: {
        status: 'Cancelled',
        icon: <CircleErrorIcon width={24} height={24} />,
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          `${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )}${isSocialCreditOrganization ? '' : ` by ${underwriterName}`}`
      },
      APPROVED: {
        status: 'Approved',
        icon: <CheckIcon width={24} height={24} />,
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          `${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )}${isSocialCreditOrganization ? '' : ` by ${underwriterName}`}`
      },
      AUTO_APPROVED: {
        status: 'Auto Approved',
        icon: <CheckIcon width={24} height={24} />,
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          `${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )}${isSocialCreditOrganization ? '' : ` by ${underwriterName}`}`
      },
      IN_PROGRESS: {
        status: 'Assessment in progress',
        icon: (
          <IconWrapper>
            <SpinnerIcon />
          </IconWrapper>
        ),
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          ` Started on ${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )} by ${underwriterName}`
      },
      ACCOUNT_REQUESTED: {
        status: 'Additional account has been requested',
        icon: (
          <IconWrapper>
            <RequestedIcon />
          </IconWrapper>
        ),
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          ` Started on ${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )} by ${underwriterName}`
      },
      ACCOUNT_UPDATED: {
        status: 'Assessment in progress',
        icon: (
          <IconWrapper>
            <SpinnerIcon />
          </IconWrapper>
        ),
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          ` Started on ${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )} by ${underwriterName}`
      },
      RESCORING: {
        status: 'Rescoring',
        icon: (
          <IconWrapper>
            <SpinnerIcon />
          </IconWrapper>
        ),
        period: ({ statusAt, underwriterName }: TPeriodProps) => ''
      },
      APPROVED_PENDING_RETRIEVER: {
        status: 'Assessment is in progress',
        icon: (
          <IconWrapper>
            <SpinnerIcon />
          </IconWrapper>
        ),
        period: ({ statusAt, underwriterName }: TPeriodProps) =>
          ` Started on ${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
            new Date(statusAt),
            HOUR_MINUTE
          )} by ${underwriterName}`
      }
    }),
    [isSocialCreditOrganization]
  )
}

function useBorrowerName() {
  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  return useMemo(() => {
    if (!headerData) {
      return null
    }
    if (
      isCustomerService
      || (!isCustomerService
        && (['APPROVED', 'DECLINED', 'CANCELLED', 'APPROVED_PENDING_RETRIEVER', 'AUTO_APPROVED'] as TStatus[]).includes(
          headerData.status
        ))
    ) {
      return headerData?.borrowerName
    }

    return `ID:${headerData.wlcfId || headerData.id}`
  }, [headerData])
}

function useIsGenerateSccLinkExists() {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  const hasAppropriateStatus = headerData?.status !== 'APPROVED'

  return useGenerateSccLinkOrganizationCheck() && hasAppropriateStatus
}

const noDataFound = 'No data found.'

function useIdentityTooltipText() {
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()

  return useMemo(() => {
    if (!headerData) {
      return ''
    }
    const {
      plaidIdentityCheck,
      dataOneIdentityCheck,
      plaidBorrowerIdentityNames,
      dataOneBorrowerIdentityNames
    } = headerData

    if (!dataOneBorrowerIdentityNames?.length && !plaidBorrowerIdentityNames?.length) {
      return noDataFound
    }
    return (
      <div>
        {!!dataOneBorrowerIdentityNames?.length && dataOneIdentityCheck !== null && (
          <div>{identityVerifiedText('d.One', dataOneIdentityCheck)}</div>
        )}
        {!!plaidBorrowerIdentityNames?.length && plaidIdentityCheck !== null && (
          <div>{identityVerifiedText('Plaid', plaidIdentityCheck)}</div>
        )}
      </div>
    )
  }, [headerData])
}

const Assessment = () => {
  const { authData } = useAuthContext()
  const isCustomerService = useHasCurrentUserCustomerServiceRole()
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const {
    headerQuery: { data: headerData }
  } = useApplicationHeaderContext()
  const { data } = useAddressQuery()

  const isPlendOrganization = useIsPlendOrganization()
  const statusTextMap = useStatusTextMap()
  const handleCopy = useHandleCopy()
  const tooltipText = useIdentityTooltipText()
  const currentBorrower = useBorrowerName()
  const isGenerateSccLinkExists = useIsGenerateSccLinkExists() && headerData?.sccLinkAvailable
  if (!headerData) {
    return null
  }

  const {
    underwriterName,
    status,
    statusAt,
    underwriterId,
    id,
    plaidBorrowerIdentityNames,
    reconnectLinkAvailable,
    dataOneIdentityCheck,
    plaidIdentityCheck,
    borrowerName,
    borrowerDateOfBirth
  } = headerData
  const underwriterNameFormatted = underwriterName ? underwriterName.split(' ')[0] : ''
  const isAssessmentStartedUnderwriter = authData?.id === underwriterId
  const isStatusOnProgress = status === 'IN_PROGRESS' || status === 'ACCOUNT_REQUESTED' || status === 'ACCOUNT_UPDATED'
  const isButtonHidden = status !== 'APPROVED' && status !== 'DECLINED'
  const isAssessmentNotStarted = status !== 'NEW'
  const isAssessmentNotRescoring = status !== 'RESCORING'
  const numberOfNames = plaidBorrowerIdentityNames && plaidBorrowerIdentityNames.length > 1
    ? `, +${Number(plaidBorrowerIdentityNames.length) - 1}`
    : null

  const isCancelledStatus = ['CANCELLED'].includes(status)

  const borrowersTitle = headerData.plaidBorrowerIdentityNames?.slice(1).join(', ')

  const plendIdentity = `Plaid name: ${plaidBorrowerIdentityNames?.[0]}`

  const isPlendApprovedPendingRetriever = status === 'APPROVED_PENDING_RETRIEVER' && isPlendOrganization

  return (
    <>
      <TitleStyled>
        <BorrowerInfo>
          {currentBorrower}
          <BorrowerName />
          {isPlendApprovedPendingRetriever && (
            <CopyButton
              width={20}
              height={20}
              title="First Name and Last Name"
              copiedValue={borrowerName ?? '-'}
            />
          )}
          {!isCustomerService && (
            <>
              <CustomTooltip arrow title={tooltipText} placement="top">
                <IdentityIconStyled>
                  <IdentityIcon
                    iconSize={20}
                    dataOneIdentityCheck={dataOneIdentityCheck}
                    plaidIdentityCheck={plaidIdentityCheck}
                  />
                </IdentityIconStyled>
              </CustomTooltip>
            </>
          )}
          <MarkApplication
            match={
              isCustomerService
                ? ['FULL_NAME']
                : ['FULL_NAME', 'EMAIL', 'PHONE', 'POSTCODE', 'FULL_ADDRESS']
            }
            iconWidthHeight={18}
            width={376}
            placement="right"
          />
        </BorrowerInfo>
        {!isCustomerService
          && !isSocialCreditOrganization
          && ['APPROVED', 'DECLINED', 'APPROVED_PENDING_RETRIEVER'].includes(status) && (
          <IdentityWrapStyled>
            <DataOneIdentity />
            <PlendIdentity>
              {plaidBorrowerIdentityNames && plaidBorrowerIdentityNames.length > 1 ? (
                <CustomTooltip arrow title={borrowersTitle || ''} placement="top">
                  <PlendIdentityDetailes>
                    {plendIdentity}
                    {numberOfNames}
                  </PlendIdentityDetailes>
                </CustomTooltip>
              ) : (
                <div>{plaidBorrowerIdentityNames?.[0] ? plendIdentity : null}</div>
              )}
            </PlendIdentity>
          </IdentityWrapStyled>
        )}
      </TitleStyled>
      <OverViewContainerStyled>
        {isAssessmentNotStarted && (
          <>
            <OverViewHeaderStyled centered={!isAssessmentNotRescoring}>
              {!isStatusOnProgress ? (
                statusTextMap[status as TStatusAssessment].icon
              ) : !isAssessmentStartedUnderwriter ? (
                <CircleTakenIcon />
              ) : (
                statusTextMap[status as TStatusAssessment].icon
              )}
              <OverViewBoxStyled>
                <h4>
                  {!isStatusOnProgress
                    ? statusTextMap[status as TStatusAssessment].status
                    : !isAssessmentStartedUnderwriter
                      ? 'The application was taken'
                      : statusTextMap[status as TStatusAssessment].status}
                  <DeclinedDetailsLink id={id} status={status as TStatusAssessment} />
                </h4>
                {isAssessmentNotRescoring && (
                  <p>
                    {isAssessmentStartedUnderwriter
                      ? statusTextMap[status as TStatusAssessment].period({
                        statusAt,
                        underwriterName: underwriterNameFormatted
                      })
                      : `${format(new Date(statusAt), SLASH_DATE_FORMAT)} at ${format(
                        new Date(statusAt),
                        HOUR_MINUTE
                      )}${isSocialCreditOrganization ? '' : ` by ${underwriterNameFormatted}`}`}
                  </p>
                )}
                <AssessmentCancel />
                {isCancelledStatus && !isCustomerService && <AssessmentRevertToNew />}
                {isAssessmentNotRescoring && (
                  <LinksWrapStyled>
                    <HistoryLog />
                    {isAssessmentStartedUnderwriter && isPlendApprovedPendingRetriever && (
                      <CopyButton
                        title="First Name, Last Name, Date Of Birth and Postcode"
                        copiedValue={`${borrowerName}\n${format(
                          new Date(borrowerDateOfBirth ?? ''),
                          'dd/LL/yyyy'
                        )}\n${data?.address?.postCode}`}
                      >
                        <CopyWrapperStyled>
                          <FileCopyIcon />
                          <div>Copy user info</div>
                        </CopyWrapperStyled>
                      </CopyButton>
                    )}
                    {/* {isGenerateSccLinkExists
                       && <GenerateSccLink loanApplicationId={id} />} */}
                  </LinksWrapStyled>
                )}
              </OverViewBoxStyled>
            </OverViewHeaderStyled>
            {isCustomerService && isAssessmentNotRescoring && reconnectLinkAvailable && (
              <AssessmentSendReConnectLink />
            )}
          </>
        )}
        <Rescore />
        {isAssessmentNotStarted
          && !isCustomerService
          && !isSocialCreditOrganization
          && isAssessmentNotRescoring && (
          <OverViewBodyStyled>
            {isButtonHidden && isAssessmentStartedUnderwriter && !isCancelledStatus && (
              <>
                <AssessmentApprove />
                <AssessmentDecline />
                <AssessmentDropOut />
              </>
            )}
            {(['IN_PROGRESS', 'ACCOUNT_REQUESTED', 'ACCOUNT_UPDATED'].includes(status)
                || isPlendApprovedPendingRetriever)
                && !isAssessmentStartedUnderwriter && <AssessmentRevertToNew />}
            {buttonsMap[status as TStatusAssessment]}
            {reconnectLinkAvailable && <AssessmentSendReConnectLink asMenu />}
          </OverViewBodyStyled>
        )}
        {!isAssessmentNotStarted && isAssessmentNotRescoring && !isCustomerService && (
          <AssessmentStart />
        )}
        {!isAssessmentNotStarted && isAssessmentNotRescoring && (
          <>
            <AssessmentCancel />
            <LinksWrapStyled>
              <HistoryLog />
              {/* {isGenerateSccLinkExists &&
              <GenerateSccLink loanApplicationId={id} />} */}
            </LinksWrapStyled>
          </>
        )}
      </OverViewContainerStyled>
    </>
  )
}

export default () => (
  <AssessmentProvider>
    <Assessment />
  </AssessmentProvider>
)
