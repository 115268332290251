import styled from 'styled-components/macro'
import { fontFamily } from '../../../core/styles/mixins'
import CustomSelect, { CustomSelectWrapper } from '../../../core/components/CustomSelect'
import PreviewTable from './PreviewTable'
import { useClearScorePricingContext } from '../state/clearScorePricing'
import { useState, MouseEvent } from 'react'
import { TableViewType } from '../types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

const Wrapper = styled.div`
  padding: 16px 24px 24px;

  ${CustomSelectWrapper} {
    margin-top: 0;
    min-width: 77px;
  }
`

const TitleStyled = styled.span`
  ${fontFamily('Inter')};
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.darkBlack};
`

const Filter = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding-bottom: 14px;
  margin: 8px 0 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey200};
`

const AOScore = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const Label = styled.span`
  ${fontFamily('Inter')};
  font-size: 16px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.darkBlack};

  & strong {
    font-weight: 600;
  }
`

const Row = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
`

const ToggleButtonStyled = styled(ToggleButton)`
  && {
    padding: 4px 10px;
    color: ${({ theme }) => theme.colors.darkBlack};
    text-transform: none;
    width: 120px;

    &.Mui-selected {
      background-color: ${({ theme }) => theme.colors.blue500};
      color: ${({ theme }) => theme.colors.white};

      &:hover {
        background-color: ${({ theme }) => theme.colors.blue500};
        opacity: 0.8;
      }
    }
  }
`

const ScoringPreview = () => {
  const {
    clearScorePreviewData: { data, isClearScorePreviewLoading },
    onChangeAoFilter,
    aoScoreOptions,
    aoScore
  } = useClearScorePricingContext()

  const [tableView, setTableView] = useState<TableViewType>('INTEREST')

  const handleChangeTableView = (_: MouseEvent<HTMLElement>, newValue: TableViewType) => setTableView(newValue)

  return (
    <Wrapper>
      <Row>
        <TitleStyled>Preview</TitleStyled>
        <ToggleButtonGroup
          value={tableView}
          exclusive
          onChange={handleChangeTableView}
        >
          <ToggleButtonStyled value='INTEREST' aria-label='centered'>
            Interest rate
          </ToggleButtonStyled>
          <ToggleButtonStyled value='APR' aria-label='left aligned'>
            APR
          </ToggleButtonStyled>
        </ToggleButtonGroup>
      </Row>
      <Filter>
        <AOScore>
          <Label>AO Score</Label>
          <CustomSelect
            disabled={isClearScorePreviewLoading}
            width={77}
            placeholder='AO Score'
            currentValue={aoScore}
            handleChange={onChangeAoFilter}
            options={aoScoreOptions}
          />
        </AOScore>
        <Label>
          Credit Margin:
          <strong>
            {' '}
            {data?.creditAdjustment}
            %
          </strong>
        </Label>
      </Filter>

      {data && <PreviewTable sourceData={data} tableType={tableView} />}
    </Wrapper>
  )
}

export default ScoringPreview
