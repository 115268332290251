import React, { FC, useMemo } from 'react'
import styled from 'styled-components/macro'
import { UseQueryResult } from 'react-query/types/react/types'
import Card, { CardStyled } from '../../../core/components/Card'
import InnerLayout, { HeaderStyled } from '../../../core/components/InnerLayout'
import Tabs from './Tabs'
import { flex, fontFamily } from '../../../core/styles/mixins'
import { EligibilityProvider, useEligibilityContext } from '../state/eligibilityState'
import { ValidationRulesBaseTable } from '../../validationRulesBase'
import { TValidationRule } from '../../validationRulesBase/types'

const WrapStyled = styled.div`
    height: 100%;

    ${HeaderStyled} {
        ${flex({ justify: 'space-between', align: 'flex-start' })};
        flex-direction: column;
        min-height: 116px;
        position: relative;
        padding-bottom: 0;
    }

    ${CardStyled} {
        width: 100%;
        max-width: 1248px;
        margin: 0 auto;
        overflow: auto;
        height: auto;
    }
`

const TitleStyled = styled.div`
    ${fontFamily('Inter')};
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
`

const TablesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const TableTitle = styled.div`
    ${fontFamily('Inter')};
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
`

const TABLE_TITLES: { [key: string]: string } = {
  MAIN_ACCOUNT_VALIDATION: 'Account validation',
  DEMOGRAPHIC_AND_BUREAUX_VALIDATION: 'Demographic and Bureaux Validation',
  OB_DECLINES_VALIDATION: 'OB Declines Validation'
}

const Eligibility: FC = () => {
  const { validationRulesQuery } = useEligibilityContext()

  const queries = useMemo(() => {
    if (!validationRulesQuery?.data) return null

    const { data, ...restQueryData } = validationRulesQuery
    const categoryOrder = ['MAIN_ACCOUNT_VALIDATION', 'OB_DECLINES_VALIDATION', 'DEMOGRAPHIC_AND_BUREAUX_VALIDATION']

    return data.map((el) => ({ ...restQueryData, data: el.configurations, category: el.category }))
      .sort((a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category))
  }, [validationRulesQuery])

  return (
    <WrapStyled>
      <InnerLayout
        header={(
          <>
            <TitleStyled>Eligibility</TitleStyled>
            <Tabs />
          </>
        )}
      >
        <TablesWrapper>
          {queries?.map((el) => {
            const { category, ...data } = el
            return (
              <div key={category}>
                <TableTitle>{TABLE_TITLES[category]}</TableTitle>
                <Card noPadding>
                  <ValidationRulesBaseTable
                    isEligibility
                    validationRulesQuery={data as unknown as UseQueryResult<TValidationRule[]>}
                  />
                </Card>
              </div>
            )
          })}
        </TablesWrapper>

      </InnerLayout>
    </WrapStyled>
  )
}

export default () => (
  <EligibilityProvider>
    <Eligibility />
  </EligibilityProvider>
)
