import { FC } from 'react'
import styled from 'styled-components/macro'
import { flex } from '../../../../core/styles/mixins'
import CustomInput, { CustomTextField } from '../../../../core/components/CustomInput'
import RuleDropDown from '../RuleDropDown'
import { useFormContext } from '../../state/formState'
import { floatFormat } from '../../../../core/utils/formUtils'
import { isDisabled } from '../../utils/tableUtils'
import { TValidationRule } from '../../types'
import { loanPurposeDescription } from './RuleTableCell'

export const DisabledTextStyled = styled.div`
    opacity: 0.5;
`

const RuleDropDownContainer = styled.div`
    ${flex({ justify: 'flex-start' })};
    flex-direction: column;
`

const PeriodInputWrapStyled = styled.div`
    ${flex({ justify: 'flex-start' })};
    flex-direction: column;

    ${CustomTextField} {
        width: 64px;
        height: 32px;
        margin-bottom: 4px;
    }
`

type TProps = {
  validationRule: TValidationRule
}

const ValidationRuleCell: FC<TProps> = ({ validationRule }) => {
  const { selectedRowId, control, isEditFormValueInitialized } = useFormContext()
  const { validationRules, daysPeriodFlag, id, description } = validationRule
  if (description === loanPurposeDescription) {
    return <div>-</div>
  }

  const isDaysPeriod = !!validationRules.filter(({ daysPeriod }) => daysPeriod).length
  if (selectedRowId === String(id) && isEditFormValueInitialized && isDaysPeriod) {
    if (daysPeriodFlag) {
      return (
        <RuleDropDownContainer>
          <RuleDropDown subName="daysPeriod" idx={0} control={control} />
        </RuleDropDownContainer>
      )
    }
    return (
      <PeriodInputWrapStyled>
        {validationRules.map((item, idx) => (
          <CustomInput
            key={item.id}
            handleChange={floatFormat}
            name={`rules.${idx}.daysPeriod`}
            control={control}
            rules={{ required: true }}
            disabled={validationRule.validationStage === 'TU_MONTHS_OLDEST_ACCOUNT_OPENED'}
          />
        ))}
      </PeriodInputWrapStyled>
    )
  }

  return (
    <>
      {isDisabled(validationRule)
        ? (
          <DisabledTextStyled>
            {validationRules.map((item) => item.daysPeriod)}
          </DisabledTextStyled>
        )
        : validationRules.map((item) => item.daysPeriod)}
    </>
  )
}

export default ValidationRuleCell
