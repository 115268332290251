import { useMutation, useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../../core/api/apiClient'
import {
  TFilter,
  TEditValidationRuleParams,
  TValidationRule,
  TLoanPurposeRawResponse,
  TEmploymentStatus, TValidationCategories
} from './types'
import { queryClient } from '../../core/api/queryClient'

export enum QueryKeys {
  LoanPurposeRaw = 'LoanPurposeRaw',
  ValidationRules = 'ValidationRules',
  EmploymentStatus = 'EmploymentStatus'
}

async function getValidationRules(filter?: TFilter): Promise<TValidationRule[]> {
  try {
    const response = await apiClient(`/configuration/validation?type=${filter}`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useValidationRulesQuery(filter?: TFilter) {
  return useQuery(
    [QueryKeys.ValidationRules, filter],
    () => getValidationRules(filter),
    {
      enabled: !!filter && filter !== 'EXCEPTIONS'
    }
  )
}

async function getEligibilityValidationRules(filter?: TFilter): Promise<TValidationCategories[]> {
  try {
    const response = await apiClient(`/configuration/validation/eligibility?type=${filter}`)
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useEligibilityValidationRulesQuery(filter?: TFilter) {
  return useQuery(
    [QueryKeys.ValidationRules, filter],
    () => getEligibilityValidationRules(filter),
    {
      enabled: !!filter && filter !== 'EXCEPTIONS'
    }
  )
}

async function toggleSwitch({ id, enabled }: Partial<TValidationRule>): Promise<void> {
  try {
    await apiClient.put(`/configuration/validation/${id}/status`, { enabled: !enabled })
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useToggleValidationRule() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id, enabled }: Partial<TValidationRule>) => toggleSwitch({ id, enabled }),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.ValidationRules)
    }
  )
}

async function editValidationRule({ id, rules }: TEditValidationRuleParams): Promise<void> {
  try {
    await apiClient.put(`/configuration/validation/${id}`, rules)
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useEditValidationRule() {
  return useMutation(
    (params: TEditValidationRuleParams) => editValidationRule(params),
    {
      onSuccess: () => queryClient.invalidateQueries(QueryKeys.ValidationRules)
    }
  )
}

export function useLoanPurposeRawQuery() {
  return useQuery<TLoanPurposeRawResponse>(
    [QueryKeys.LoanPurposeRaw],
    async () => {
      try {
        const response = await apiClient('/dictionaries/loan-purpose/raw')
        return response.data
      } catch (e) {
        throw new Error('Something went wrong')
      }
    }
  )
}

async function getEmploymentStatus(): Promise<TEmploymentStatus[]> {
  try {
    const response = await apiClient('dictionaries/employment-status')
    return response.data
  } catch (e) {
    throw new Error('Something went wrong')
  }
}

export function useEmploymentStatus(isBureaux: boolean) {
  return useQuery(
    [QueryKeys.EmploymentStatus],
    getEmploymentStatus,
    {
      enabled: isBureaux
    }
  )
}
