import styled from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import CustomTabs, { MaterialTabsStyled } from '../../../../core/components/tabs/Tabs'
import Tab from '../../../../core/components/tabs/Tab'
import { useApplicationsContext } from '../state/useApplications'
import { TFilter } from '../types'
import { useIsPlendOrganization, useIsSocialCreditOrganization } from '../../../auth/hooks'

const WrapStyled = styled.div`
  ${MaterialTabsStyled} {
    position: absolute;
    bottom: 0;
    left: 24px;
  }
`

type TTabConfigItem = { name: string; key?: TFilter }

function useTabs(): TTabConfigItem[] {
  const isSocialCreditOrganization = useIsSocialCreditOrganization()
  const isPlendOrganization = useIsPlendOrganization()

  return useMemo(() => [
    {
      name: 'All applications'
    },
    ...(!isSocialCreditOrganization
      ? [
        {
          name: 'New',
          key: 'NEW'
        },
        {
          name: 'In Progress',
          key: 'IN_PROGRESS'
        }
      ] as TTabConfigItem[]
      : []
    ),
    ...(isPlendOrganization
      ? [{
        name: 'Approved, pending Retriever',
        key: 'APPROVED_PENDING_RETRIEVER'
      }] as TTabConfigItem[]
      : []
    ),
    {
      name: 'Auto-Approve',
      key: 'AUTO_APPROVED'
    },
    {
      name: 'Approved',
      key: 'APPROVED'
    },
    ...(!isSocialCreditOrganization
      ? [{
        name: 'Cancelled',
        key: 'CANCELLED'
      }] as TTabConfigItem[]
      : []
    ),
    {
      name: 'Declined',
      key: 'DECLINED'
    },
    ...(!isSocialCreditOrganization
      ? [{
        name: 'My loans',
        key: 'MY_LOANS'
      }] as TTabConfigItem[]
      : []
    )
  ], [isSocialCreditOrganization, isPlendOrganization])
}

function useTabsHandle() {
  const { handleChangeFilter, filter } = useApplicationsContext()

  const tabs = useTabs()

  const initialFilterIndex = tabs.findIndex(({ key }) => key === filter) || 0

  const [filerIndex, setFilerIndex] = useState(initialFilterIndex)

  const filterKey = useMemo(() => tabs[filerIndex].key, [filerIndex])

  useEffect(() => handleChangeFilter(filterKey), [filterKey])

  return {
    filerIndex,
    setFilerIndex
  }
}

const Tabs = () => {
  const { filerIndex, setFilerIndex } = useTabsHandle()
  const tabs = useTabs()

  const tabNames = useMemo(() => tabs.map(({ name }) => name), [])

  return (
    <WrapStyled>
      <CustomTabs
        value={filerIndex}
        onChange={(_, newValue) => {
          setFilerIndex(newValue)
        }}
      >
        {tabNames.map((name) => (
          <Tab key={name} label={name} />
        ))}
      </CustomTabs>
    </WrapStyled>
  )
}

export default Tabs
